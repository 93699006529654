<template>
    <div>
   <card>
    <div class="row">
        <div class="col" slot="header"><h4 class="card-title">Submission</h4>
    </div>
    <div class="col offset-9">
        <router-link to="Addsubmissions">
          <base-button type="info">Add</base-button>
        </router-link>
    </div>
    </div>
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="papersList" border>
            <el-table-column min-width="60" prop="paper_uniqueid" label="Paper ID">
            </el-table-column>
            <el-table-column min-width="60" prop="upload_date" label="Upload Date">
            </el-table-column>
            <el-table-column
              min-width="120"
              prop="paper_title"
              align="center"
              label="Title"
            >
            </el-table-column>
            <el-table-column
              min-width="50"
              prop="paper_articletype"
              align="center"
              label="Article Type"
            >
            </el-table-column>
            <el-table-column
              min-width="50"
              prop="paper_issuetype"
              align="right"
              label="Issue Type"
            >
            </el-table-column>
            <el-table-column
              min-width="50"
              header-align="right"
              align="right"
              prop="paper_status"
              label="Status"
            >
            </el-table-column>
            <el-table-column
                label="Operations">
              <template slot-scope="scope">
                  <base-button type="info" @click="viewPaper(scope.$index, scope.row)" class="btn-sm mr-2">View</base-button>
                <base-button type="warning" @click="editPaper(scope.$index, scope.row)" class="btn-sm mr-2">Edit</base-button>
                <base-button type="danger" @click="deletePaper(scope.$index, scope.row)" class="btn-sm">Delete</base-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
        </div>
</template>

<script>
import { Table, TableColumn, } from 'element-ui';
import Buttons from '../Components/Buttons.vue';
export default {
    components: {
        Buttons,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
},
  data: function() {
    return {
      papersList: []
    }
  },
  methods: {
    getSubmissions: function() {
      this.api.getDataModule('papers/'+store.state.journalId+'/getpaperslist', 'journals/v1/').then((res) => {
        this.papersList = res.data.papersList
      },(err) => {
        console.log(err)
      })
    },
    viewPaper(index, row) {
      window.open(row.paper_url, '_blank')
      console.log(row)
    },
    editPaper(index, row) {
      this.$router.push({path: 'editsubmission', query: {paperid: row.paper_id}})
      console.log(row)
    },
    deletePaper(index, row) {
      console.log(row)
      let paperDetails = {
        paper_id: row.paper_id
      }
      this.api.putDataModule('papers/'+store.state.journalId+'/deletepaper', paperDetails, 'journals/v1/').then((res) => {
        console.log(res.data.status)
      }, (err) => {
        console.log(err)
      })
    }
  },
  mounted: function() {
    this.getSubmissions()
  }
}
</script>

<style>

</style>
