<template>
    <div>
   <card>
    <div class="row">
        <div class="col" slot="header"><h4 class="card-title">Enquiry</h4>
    </div>
    <div class="col offset-9">
          <base-button type="info" @click="addNewEnquiry">Add</base-button>
        
    </div>
    </div>
    
      <div class="row">
        <div class="col-sm-12">
          <el-table>
            <el-table-column min-width="50" label="SI NO" align="center">
              <div class="photo" slot-scope="{ row }">
                <img :src="row.img" alt="Table image" />
              </div>
            </el-table-column>
            <el-table-column min-width="80" prop="name" label="Date">
            </el-table-column>
            <el-table-column min-width="100" prop="job" label="Customer Name">
            </el-table-column>
            <el-table-column
              min-width="80"
              prop="since"
              align="center"
              label="Mobile"
            >
            </el-table-column>
            <el-table-column
              min-width="80"
              align="right"
              label="Email"
            >
            </el-table-column>
            <el-table-column
              min-width="80"
              header-align="right"
              align="right"
              label="Subject"
            >
            </el-table-column>
            <el-table-column
              min-width="80"
              header-align="right"
              align="right"
              label="Comment"
            >
            </el-table-column>
            <el-table-column
              min-width="80"
              header-align="right"
              align="right"
              label="Status"
            >
            </el-table-column>
            <el-table-column
              min-width="80"
              header-align="right"
              align="right"
              label="Action"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
        </div>
</template>

<script>
import { Table, TableColumn, } from 'element-ui';
import Buttons from '../Components/Buttons.vue';
export default {
    components: {
        Buttons,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
},
data() {
  return {

  }
},
methods: {
  addNewEnquiry() {
    this.$router.push({path: 'add-enquiry'})
  }
}
}
</script>

<style>

</style>