<template>
    <div>
   <card>
    <div class="row">
        <div class="col" slot="header"><h4 class="card-title">Authors</h4>
    </div>
    <div class="col offset-9">
          <base-button type="info" @click="addNewAuthor">Add</base-button>
        
    </div>
    </div>
    
      <div class="row">
        <div class="col-sm-12">
          <el-table :data="tableData">
            <el-table-column  label="First Name" align="center">
            </el-table-column>
            <el-table-column  label="Last Name">
            </el-table-column>
            <el-table-column  label=" User Name">
            </el-table-column>
            <el-table-column
              align="center"
              label="Contact Number"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="State"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
        </div>
</template>

<script>
import { Table, TableColumn, } from 'element-ui';
import Buttons from '../Components/Buttons.vue';
export default {
  components: { Buttons,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
 },
  data() {
    return {
       
    }
  },
  methods: {
    addNewAuthor() {
        this.$router.push({path: 'addauthors'})
    }
  }

}
</script>

<style>

</style>