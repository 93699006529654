<template>
    <div>
      <card>
        <h4>Add Member Details</h4>
        <form>
          <div class="row mb-3">
            <div class="col">
              <div class="row p-2">
                <div class="col-3">
                  <label for="firstname" class="col col-form-label">
                    <b>Name</b>
                  </label>
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" id="firstname" placeholder="Name" v-model="userdetails.name"/>
                </div>
              </div>
  
              <div class="row p-2">
                <div class="col-3">
                  <label for="lastname" class="col col-form-label">
                    <b>Email</b>
                  </label>
                </div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    id="lastname"
                    placeholder="Email"
                    v-model="userdetails.email"
                  />
                </div>
              </div>
  
              <div class="row p-2">
                <div class="col-3">
                  <label for="email" class="col col-form-label"
                    ><b>Role</b></label
                  >
                </div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    placeholder="Role"
                    v-model="userdetails.role"
                  />
                </div>
              </div>
  
              <div class="row p-2">
                <div class="col-3">
                  <label for="contactprimary" class="col col-form-label"
                    ><b>Designation</b></label
                  >
                </div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    id="contactnumberprimary"
                    placeholder="Desingnation"
                    v-model="userdetails.designation"
                  />
                </div>
              </div>
  
              <div class="row p-2">
                <div class="col-3">
                  <label for="contactsecondary" class="col col-form-label"
                    ><b>Address</b></label
                  >
                </div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    id="contactsecondary"
                    placeholder="Address"
                    v-model="userdetails.address"
                  />
                </div>
              </div>
  
              <div class="row p-2">
                <div class="col-3">
                  <label for="addressline1" class="col col-form-label"
                    ><b>Research Area</b></label
                  >
                </div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    id="addressline1"
                    placeholder="Research Area"
                    v-model="userdetails.researchArea"
                  />
                </div>
              </div>
  
              <div class="row p-2">
              <div class="col-3">
                <label for="country" class="col col-form-label"
                  ><b>Country</b></label
                >
              </div>
              <div class="col-4">
                <select id="country" name="country" class="form-control" v-model="userdetails.country">
                  <option value="" disabled selected hidden>
                    Select Country
                  </option>
                  <option v-for="country in countriesList" :value="country.name" :key="country.code">{{ country.name }}</option>
                </select>
              </div>
            </div>
  
              <div class="row p-2">
                <div class="col-3">
                  <label for="city" class="col col-form-label"
                    ><b>Website</b></label
                  >
                </div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    id="city"
                    placeholder="Website"
                    v-model="userdetails.website"
                  />
                </div>
              </div>
  
              <!-- <div class="row p-2">
                <div class="col-3">
                  <label for="state" class="col col-form-label"
                    ><b>Profile URL</b></label
                  >
                </div>
                <div class="col-4">
                  <input
                    type="text"
                    class="form-control"
                    id="state"
                    placeholder="Profile URL"
                    v-model="userdetails.profileUrl"
                  />
                </div>
              </div> -->
            </div>
          </div>
            <div class="col-md-4 col-sm-4">
              <h4 class="card-title">Profile Image</h4>
              <image-upload @change="onImageChange" select-text="Choose Image" />
            </div>
          <div class="row p-4 col-md-8">
            <div class="col-2">
                <base-button type="info" @click="saveUserData">Save</base-button>
            </div>
            <div class="col-2">
                <base-button type="warning" @click="cancelAuthor">Cancel</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </template>
  <script>
  import { BaseSwitch, ImageUpload } from 'src/components/index';
  import countries from '../../util/countries.js';
  import { Select, Switch, Checkbox } from "element-ui";
  import { extend } from "vee-validate";
  import { required, confirmed } from "vee-validate/dist/rules";
  extend("required", required);
  extend("confirmed", confirmed);
  export default {
    components: {
      [Select.name]: Select,
      [Checkbox.name]: Checkbox,
      BaseSwitch,
    ImageUpload
    },
    data() {
      return {
        userdetails: {
          logoURL: null
        },
        countriesList: countries.countries,
      };
    },
    methods: {
      // saveUserData() {
      //   this.api.postDataModule('editorial/'+store.state.journalId+'/addmember', this.userdetails, 'journals/v1/').then((res)=>{
      //       console.log(res.data)
      //   }, (err) => {
      //   console.log(err)
      // })
      // },
    onImageChange(file) {
      this.userdetails.logoURL = file
    },
    saveUserData() {
      let formData = new FormData()
      let fileType = this.getFileType()
      formData.append('logoURL', this.userdetails.logoURL)
      formData.append('fileType', fileType)
      this.api.uploadFile('editorial/'+store.state.journalId+'/profileimage', formData, 'journals/v1/').then((res) => {
        this.userdetails.journalId = store.state.journalId
        this.userdetails.profileUrl = res.data.status
        this.userdetails.fileType = fileType
        this.createNewMember()
      }, (err) => {
        console.log(err)
      })
    },
    createNewMember() {
      this.api.postDataModule('editorial/'+store.state.journalId+'/addmember', this.userdetails, 'journals/v1/').then((res)=>{
        console.log(res.data)
        if(res.data.status) {
          this.$router.push({path: 'editorial'})
        }
      }, (err) => {
        console.log(err)
      })
    },
    getFileType: function() {
        if(this.userdetails.logoURL.name.endsWith('png')){
          return 'png'
        } else if(this.userdetails.logoURL.name.endsWith('jpg')){
          return 'jpg'
        } else if(this.userdetails.logoURL.name.endsWith('jpeg')){
          return 'jpeg'
        }
    },
    cancelAuthor() {
      this.$router.push({path: 'editorial'})
    }
    }
  }
  </script>
  <style scoped>
  .mail {
    font-size: 12px;
  }
  button {
    border-radius: 10px;
    background-color: #007bff;
    color: white;
    border: none;
  }
  </style>
  
