<template>
  <div class="col-lg-12 col-md-12 pt-4">
    <card>
      <form>
        <h4 class="p-1">Edit Paper</h4>
        <div class="p-4 col-8">

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="title" class="col col-form-label"
                ><b>Title :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="title"
                placeholder="Title"
                v-model="paperDetails.paper_title"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="author" class="col col-form-label"
                ><b>Author :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="author"
                placeholder="Author"
                v-model="paperDetails.paper_author"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="article" class="col col-form-label"
                ><b>Article Type :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="article"
                placeholder="Article Type"
                v-model="paperDetails.paper_articletype"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="doi" class="col col-form-label"
                ><b>DOI NO :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="doi"
                placeholder="DOI NO "
                v-model="paperDetails.paper_doi"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="pages" class="col col-form-label"
                ><b>Pages :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="pages"
                placeholder="Pages"
                v-model="paperDetails.paper_pages"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label
                class="col col-form-label"
                for="exampleFormControlTextarea1"
                ><b>Abstract :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                v-model="paperDetails.paper_abstract"
              ></textarea>
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="status" class="col col-form-label"
                ><b>Status :</b></label
              >
            </div>

            <div class="col-sm-6 col-lg-9">
              <el-select
                class="select-primary"
                size="large"
                placeholder="Active"
                id="status"
                v-model="paperDetails.activationstatus"
              >
                <el-option
                  v-for="option in selects.activestatus"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row p-4">
            <div class="col-2">
                <base-button @click="createSubmission" type="info">Save</base-button>
            </div>
            <div class="col-2">
                <base-button @click="backtoArchives" type="warning">Cancel</base-button>
            </div>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";

import { FileUpload } from "src/components/index";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,

    FileUpload,
  },
  data() {
    return {
      paperid: '',
      paperDetails: {
        paperUrl: null,
        activationstatus: ''
      },
      selects: {
        activestatus: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
        ],
      },
    };
  },
  methods: {
    createSubmission: function() {
      this.paperDetails.paperid = this.paperid
      this.api.putDataModule('archives/'+store.state.journalId+'/updatepaper', this.paperDetails, 'journals/v1/').then((res)=>{
        if(res.data.status) {
          this.$router.push({path: 'Archives'})
        }
      }, (err) => {
        console.log(err)
      })
    },
    getPaperDetails: function() {
      this.api.getDataModulePagination('archives/'+store.state.journalId+'/getpaperdetails', 'journals/v1/', 'paperid='+this.paperid).then((res)=> {
        this.paperDetails = res.data.paperdetails
      }, (err) => {
        console.log(err)
      })
    },
    createPaper: function() {
      console.log(this.paperDetails)
    },
    backtoArchives: function() {
      this.$router.push({path: 'archives'})
    },
    onFileChange: function(file) {
      this.paperDetails.paperUrl = file
    },
    getFileType: function() {
      if(this.paperDetails.paperUrl.name.endsWith('pdf')){
        return 'pdf'
      } else if(this.paperDetails.paperUrl.name.endsWith('doc')){
        return 'doc'
      } if(this.paperDetails.paperUrl.name.endsWith('docx')){
        return 'docx'
      }
    }
  },
  mounted: function() {
    console.log(this.$route.query.paperid)
    this.paperid = this.$route.query.paperid
    this.getPaperDetails()
    console.log(this.paperDetails)
  }
};
</script>
<style>
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
</style>
