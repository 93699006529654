<template>
  <div>
    <card>
      <form>
        <h4>New Submission :</h4>
        <div class="row p-3">
          <div class="col">
            <div class="row p-3">
              <div class="col-3">
                <label for="author"><b>Author :</b></label>
              </div>
              <div class="col">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Author"
                  id="author"
                  v-model="paperDetails.author"
                >
                  <el-option
                    v-for="option in authorsList"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row p-3">
              <div class="col-3">
                <label for="article"><b>Type of Article :</b></label>
              </div>
              <div class="col">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Article Type"
                  id="article"
                  v-model="paperDetails.articleType"
                >
                  <el-option
                    v-for="option in articleType"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="row p-3">
              <div class="col-3">
                <label for="exampleFormControlTextarea1"
                  ><b>Title :</b></label
                >
              </div>
              <div class="col form-group">
                <base-input
                  type="text"
                  placeholder="Article Title"
                  v-model="paperDetails.title"
                />
              </div>
            </div>

            <div class="row p-3">
              <div class="col-3">
                <label for="exampleFormControlTextarea1"
                  ><b>Abstract :</b></label
                >
              </div>
              <div class="col form-group">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  v-model="paperDetails.abstract"
                ></textarea>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="row p-3">
              <div class="col-3">
                <label for="authorship"><b>Authorship :</b></label>
              </div>
              <div class="col">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Authorship"
                  id="authorship"
                  v-model="paperDetails.authorShip"
                >
                  <el-option
                    v-for="option in authorShip"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row p-3">
              <div class="col-3">
                <label for="processing"><b>Mode of Processing :</b></label>
              </div>
              <div class="col">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Mode of Processing"
                  id="processing"
                  v-model="paperDetails.modeOfProcessing"
                >
                  <el-option
                    v-for="option in modeOfProcessing"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row p-3">
              <div class="col-3">
                <label for="issue"><b>Type of Issue :</b></label>
              </div>
              <div class="col">
                <el-select
                  class="select-primary"
                  size="large"
                  placeholder="Issue Type"
                  id="issue"
                  v-model="paperDetails.issueType"
                >
                  <el-option
                    v-for="option in issueType"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="col">
              <h4 class="card-title">Upload File</h4>
              <file-upload @change="onFileChange" select-text="Upload File" />
            </div>
          </div>
        </div>

        <div class="row p-3">
          <div class="col offset-2">
            <base-button type="info" @click="cancelAdd">Cancel</base-button>
          </div>
          <div class="col offset-2">
            <base-button type="info" @click="uploadPaper">Save</base-button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import Buttons from "../Components/Buttons.vue";
import { FileUpload } from 'src/components/index';
export default {
  components: {
    Buttons,
    FileUpload,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      paperDetails: {
        author: '',
        issueType: '',
        modeOfProcessing: '',
        authorShip: '',
        articleType: '',
        status: 'New',
        isActive: true,
        paperUrl: null
      },
      authorsList: [],
      authorShip: [
        {label: 'Corresponding Author', value: 'Corresponding Author'},
        {label: 'First Author', value: 'First Author'},
        {label: 'Co-Author', value: 'Co-Author'},
      ],
      modeOfProcessing: [
        {label: 'Normal Track', value: 'Normal Track'},
        {label: 'Fast Track', value: 'Fast Track'},
        {label: 'Express Track', value: 'Express Track'},
      ],
      articleType: [
        {label: 'Original Article', value: 'Original Article'},
        {label: 'Review Article', value: 'Review Article'},
        {label: 'Short Communication', value: 'Short Communication'},
        {label: 'Case Study', value: 'Case Study'},
        {label: 'Case Report', value: 'Case Report'},
        {label: 'Editorial Note', value: 'Editorial Note'},
        {label: 'Conference Proceeding', value: 'Conference Proceeding'},
      ],
      issueType: [
        {label: 'Regular', value: 'Regular'},
        {label: 'Special', value: 'Special'},
        {label: 'Supplementary', value: 'Supplementary'},
      ],
    };
  },
  methods: {
    cancelAdd: function() {
      this.$router.push({path: 'submissions'})
    },
    uploadPaper: function() {
      let formData = new FormData()
      let fileType = this.getFileType()
      formData.append('paperURL', this.paperDetails.paperUrl)
      formData.append('fileType', fileType)
      this.api.uploadFile('papers/'+store.state.journalId+'/uploadpaperdata', formData, 'journals/v1/').then((res) => {
        this.paperDetails.journalId = store.state.journalId
        this.paperDetails.paperUrl = res.data.status
        this.paperDetails.fileType = fileType
        this.createSubmission()
      }, (err) => {
        console.log(err)
      })
    },
    createSubmission: function() {
      this.api.postDataModule('papers/'+store.state.journalId+'/addsubmission', this.paperDetails, 'journals/v1/').then((res)=>{
        console.log(res.data)
      }, (err) => {
        console.log(err)
      })
    },
    getFileType: function() {
        if(this.paperDetails.paperUrl.name.endsWith('pdf')){
          return 'pdf'
        } else if(this.paperDetails.paperUrl.name.endsWith('doc')){
          return 'doc'
        } if(this.paperDetails.paperUrl.name.endsWith('docx')){
          return 'docx'
        }
    },
    onFileChange: function(file) {
      this.paperDetails.paperUrl = file
    }
  },
  mounted() {
    console.log('Add Submission')
  }
};
</script>

<style>
/* .el-input {
    position: relative;
    font-size: 12px;
    display: inline-block;
    width: 550px;
} */
</style>
