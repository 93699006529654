<template>
  <div class="col-lg-12 col-md-12 pt-4">
    <card>
      <form>
        <h3 class="p-1">Add Paper</h3>
        <div class="p-4 col-8">
          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="image" class="col col-form-label"
                ><b>File :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <file-upload @change="onFileChange" select-text="Upload File" />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="year" class="col col-form-label"><b>Year :</b></label>
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="year"
                placeholder="Year"
                v-model="paperDetails.year"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="volume" class="col col-form-label"
                ><b>Volume :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="number"
                class="form-control"
                id="volume"
                placeholder="Volume"
                v-model="paperDetails.volume"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="issue" class="col col-form-label"
                ><b>Issue :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="issue"
                placeholder="Issue"
                v-model="paperDetails.issue"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="month" class="col col-form-label"
                ><b>Month :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <el-select
                class="select-primary mb-3 pagination-select"
                v-model="paperDetails.month"
                placeholder="Month"
              >
                <el-option
                  class="select-primary"
                  v-for="item in months"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="title" class="col col-form-label"
                ><b>Title :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="title"
                placeholder="Title"
                v-model="paperDetails.title"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="author" class="col col-form-label"
                ><b>Author :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="author"
                placeholder="Author"
                v-model="paperDetails.author"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="article" class="col col-form-label"
                ><b>Article Type :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="article"
                placeholder="Article Type"
                v-model="paperDetails.articleType"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="doi" class="col col-form-label"
                ><b>DOI NO :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="doi"
                placeholder="DOI NO "
                v-model="paperDetails.doinumber"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="pages" class="col col-form-label"
                ><b>Pages :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <input
                type="text"
                class="form-control"
                id="pages"
                placeholder="Pages"
                v-model="paperDetails.pages"
              />
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label
                class="col col-form-label"
                for="exampleFormControlTextarea1"
                ><b>Abstract :</b></label
              >
            </div>
            <div class="col-sm-6 col-lg-9">
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="10"
                v-model="paperDetails.abstract"
              ></textarea>
            </div>
          </div>

          <div class="row p-2">
            <div class="col-sm-4 col-lg-3">
              <label for="status" class="col col-form-label"
                ><b>Status :</b></label
              >
            </div>

            <div class="col-sm-6 col-lg-9">
              <el-select
                class="select-primary"
                size="large"
                placeholder="Active"
                id="status"
                v-model="paperDetails.activationstatus"
              >
                <el-option
                  v-for="option in selects.activestatus"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row p-4">
            <div class="col-2">
                <base-button @click="uploadPaper" type="info">Save</base-button>
            </div>
            <div class="col-2">
                <base-button @click="backtoArchives" type="warning">Cancel</base-button>
            </div>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";

import { FileUpload } from "src/components/index";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,

    FileUpload,
  },
  data() {
    return {
      paperDetails: {
        paperUrl: null,
        activationstatus: ''
      },
      months: [
        { value: 'January-February', label: 'January-February' },
        { value: 'March-April', label: 'March-April' },
        { value: 'May-June', label: 'May-June' },
        { value: 'July-August', label: 'July-August' },
        { value: 'September-October', label: 'September-October' },
        { value: 'November-December', label: 'November-December' }
      ],
      selects: {
        activestatus: [
          { value: "Active", label: "Active" },
          { value: "Inactive", label: "Inactive" },
        ],
      },
    };
  },
  methods: {
    uploadPaper: function() {
      let formData = new FormData()
      let fileType = this.getFileType()
      formData.append('paperURL', this.paperDetails.paperUrl)
      formData.append('fileType', fileType)
      this.api.uploadFile('archives/'+store.state.journalId+'/uploadpaperdata', formData, 'journals/v1/').then((res) => {
        this.paperDetails.journalId = store.state.journalId
        this.paperDetails.paperUrl = res.data.status
        this.paperDetails.fileType = fileType
        this.createSubmission()
      }, (err) => {
        console.log(err)
      })
    },
    createSubmission: function() {
      this.api.postDataModule('archives/'+store.state.journalId+'/addsubmission', this.paperDetails, 'journals/v1/').then((res)=>{
       if(res.data.status) {
          this.$router.push({path: 'Archives'})
        }
      }, (err) => {
        console.log(err)
      })
    },
    createPaper: function() {
      console.log(this.paperDetails)
    },
    backtoArchives: function() {
      this.$router.push({path: 'archives'})
    },
    onFileChange: function(file) {
      this.paperDetails.paperUrl = file
    },
    getFileType: function() {
      if(this.paperDetails.paperUrl.name.endsWith('pdf')){
        return 'pdf'
      } else if(this.paperDetails.paperUrl.name.endsWith('doc')){
        return 'doc'
      } if(this.paperDetails.paperUrl.name.endsWith('docx')){
        return 'docx'
      }
    }
  },
};
</script>
<style>
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}

.extended-forms .progress {
  margin-bottom: 30px;
}
</style>
