<template>
  <div class="row">
    <div class="col-md-12">
      <card
        class="card-plain"
        card-body-classes="table-full-width"
        header-classes="row"
      >
        <template slot="header" :class="row">
          <h3 class="card-title">Indexing</h3>
          <base-button
            type="primary"
            size="sm"
            @click="addNewIndexing"
            class="ml-4"
            >Add Indexing</base-button
          >
        </template>
        <div class="row indexing-cards-container pl-5">
          <div
            class="col-md-3 col-lg-3 col-sm-3 m-3 card"
            v-for="data in indexingList"
            :key="data.indexing_id"
          >
            <div class="row">
              <p class="pl-4 pt-2 pb-2">{{ data.indexing_name }}</p>
            </div>
            <div class="row">
              <p class="pl-4 pt-2 pb-2">{{ data.indexing_url }}</p>
            </div>
            <div class="row IndexingImage pb-3">
              <img
                :src="data.indexing_image_url"
                class="rounded mx-auto d-block"
              />
            </div>
            <div class="row pb-3">
              <div class="col pl-4 offset-1">
                <base-switch
                  class="mt-2"
                  v-model="data.isActive"
                  type="primary"
                  on-text="Active"
                  off-text="Inactive"
                ></base-switch>
              </div>
              <div class="col pl-4">
                <base-button
                  type="primary"
                  size="sm"
                  @click="deleteIndexing(data.indexing_id)"
                  class="ml-4"
                  >Delete</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </card>
      <!-- </div>
        </div>
      </card> -->
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";
import { BaseSwitch } from "src/components/index";
// import func from 'vue-editor-bridge';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseSwitch,
  },
  data() {
    return {
      indexingList: [],
    };
  },
  methods: {
    addNewIndexing() {
      this.$router.push({ path: "addindexing" });
    },
    deleteIndexing: function (dVal) {
      console.log(this.indexingList);
      let sVal = {};
      sVal.indexing_id = dVal;
      this.api
        .putData("indexing/" + store.state.journalId + "/deleteindexing", sVal)
        .then(
          (res) => {
            console.log(res.data);
          },
          (error) => {
            console.log(error);
          }
        );
    },

    getIndexingList: function () {
      this.api
        .getDataModule("indexing/" + store.state.journalId, "journals/v1/")
        .then(
          (res) => {
            console.log(res.data);
            this.indexingList = res.data.indexingList;
          },
          (err) => {
            console.log(err);
          }
        );
    },
  },
  mounted: function () {
    this.getIndexingList();
  },
};
</script> 
<style>
.table-transparent {
  background-color: transparent !important;
}
.IndexingImage img {
  height: 175px;
  width: 220px;
}

.bootstrap-switch .bootstrap-switch-handle-on {
  background-color: #1d8cf8;
  color: #fff;
}
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  background: #ff0000;
}
</style>
